import React from 'react'
import * as Styles from "./bannerService.module.scss"
import ui from "../../assets/img/UIUX.jpg"

const BannerService = ({ img, heading, detail }) => {
    return (
        <div className={`${Styles.bannerContainer} container-fluid`}>
            <img src={img} alt="logo" />
            <div className={`${Styles.serviceBanner} container`}>
                <div className={`${Styles.heading}`}>
                    <h1>{heading}</h1>
                    <p>{detail}</p>
                </div>
            </div>
        </div>

    )
}

export default BannerService