import * as React from "react"

import Layout from "../../layout"
import Seo from "../../components/seo"

const NotFoundPage = () => (
  <Layout>
    <div className="d-flex justify-content-center align-items-center flex-column p-5">
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist...</p>
    </div>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
