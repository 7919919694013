import React from "react"
import * as Styles from "./home.module.scss"
import Android from "../../assets/img/android.svg"
import ReactLogo from "../../assets/img/react.svg"
import Angular from "../../assets/img/angular.svg"
import Swift from "../../assets/img/swift.svg"
import HTML from "../../assets/img/html.svg"
import NodeImg from "../../assets/img/node.svg"
import Postgres from "../../assets/img/postgres.svg"
import Docker from "../../assets/img/docker.svg"
import Kubernetes from "../../assets/img/kubernetes.svg"
import Solidity from "../../assets/img/solidity.svg"
import "animate.css"
import ScrollAnimation from "react-animate-on-scroll"

const skills = () => {
  return (
    <section className={`${Styles.skillSectionContainer} mb-5`}>
      <div className={Styles.anySkillContainer}>
        <div className={Styles.infoWrapper}>
          <div className={Styles.heading}>
            <h2>Any team. Any skill. Any time.</h2>
          </div>
          <div className={Styles.description}>
            <h3>
              We let you manage the projects openly and our world-class,
              full-time team of developers is yours to direct.
            </h3>
          </div>
        </div>
        <div className={Styles.skillsLogoWrapper}>
          {/* <div></div> */}
          <ScrollAnimation
            animateIn="animate__animated animate__slideInUp"
            animateOut="animate__animated animate__slideInUp"
            animateOnce={true}
          >
            <div className={Styles.skillLogoColor}>
              <img src={ReactLogo} alt="logo" />
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="animate__animated animate__slideInUp"
            animateOut="animate__animated animate__slideInUp"
            animateOnce={true}
          >
            <div className={Styles.skillLogoColor}>
              <img src={Android} alt="logo" />
            </div>
          </ScrollAnimation>
        </div>
      </div>

      <div className={Styles.addingValueContainer}>
        <div className={Styles.skillsLogoWrapper}>
          <ScrollAnimation
            animateIn="animate__animated animate__slideInUp"
            animateOut="animate__animated animate__slideInUp"
            animateOnce={true}
          >
            <div className={Styles.skillLogoColor}>
              <img src={Swift} alt="logo" />
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="animate__animated animate__slideInUp"
            animateOut="animate__animated animate__slideInUp"
            animateOnce={true}
          >
            <div className={Styles.skillLogoColor}>
              <img src={HTML} alt="logo" />
            </div>
          </ScrollAnimation>
        </div>

        <div className={Styles.infoWrapper}>
          <div className={Styles.heading}>
            <h2>Adding value from day one.</h2>
          </div>
          <div className={Styles.description}>
            <h3>
              Our teams are ready to be onboard quickly and look for ways to add
              immediate impact to your proposed ideas..
            </h3>
          </div>
        </div>
      </div>

      <div className={Styles.scaleEffectivelyContainer}>
        <div className={Styles.infoWrapper}>
          <div className={Styles.heading}>
            <h2>Scale more effectively.</h2>
          </div>
          <div className={Styles.description}>
            <h3>
              Ramp up your team on-demand, or ramp down as needed. We make
              scaling simple.
            </h3>
          </div>
        </div>

        <div className={Styles.skillsLogoWrapper}>
          {/* <div></div> */}
          {/* <div></div> */}
          <ScrollAnimation
            animateIn="animate__animated animate__slideInUp"
            animateOut="animate__animated animate__slideInUp"
            animateOnce={true}
          >
            <div className={Styles.skillLogoColor}>
              <img src={Postgres} alt="logo" />
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="animate__animated animate__slideInUp"
            animateOut="animate__animated animate__slideInUp"
            animateOnce={true}
          >
            <div className={Styles.skillLogoColor}>
              <img src={NodeImg} alt="logo" />
            </div>
          </ScrollAnimation>
        </div>
      </div>
      <div className={Styles.skillLogoListContainer}>
        <div></div>
        <ScrollAnimation
          animateIn="animate__animated animate__slideInUp"
          animateOut="animate__animated animate__slideInUp"
          animateOnce={true}
        >
          <div className={Styles.skillLogoColor}>
            <img src={Docker} alt="logo" />
          </div>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="animate__animated animate__slideInUp"
          animateOut="animate__animated animate__slideInUp"
          animateOnce={true}
        >
          <div className={Styles.skillLogoColor}>
            <img src={Kubernetes} alt="logo" />
          </div>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="animate__animated animate__slideInUp"
          animateOut="animate__animated animate__slideInUp"
          animateOnce={true}
        >
          <div className={Styles.skillLogoColor}>
            <img src={Solidity} alt="logo" />
          </div>
        </ScrollAnimation>
      </div>
    </section>
  )
}

export default skills
