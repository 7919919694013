import React from 'react'
import * as Styles from "./services.scss";
import Layout from "../layout"
import BannerService from '../components/bannerService';
import ServiceDetail from '../components/serviceDetail';
import ServiceQA from '../components/serviceQA';
import ServiceProcess from '../components/serviceProcess';
import RingUs from '../components/RingUs';

const ServiceTemplate = ({ pageContext }) => {
    const { data } = pageContext;

    return (
        <>
            <Layout>
                <div className={`${Styles.serviceContainer}`}>
                    <BannerService img={data.headerImg?.url} heading={data.headerTitle} detail={data.headerLine} />
                    <ServiceDetail img={data.bodyImg?.url} heading={data.bodyTitle} detail={data.bodyDetail.bodyDetail} />
                    <ServiceQA title={data.footerTitle} detail={data.footerDetail} />
                    <ServiceProcess />
                    <RingUs />
                </div>
            </Layout>
        </>
    )
}

export default ServiceTemplate;