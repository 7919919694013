import * as React from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import Layout from "../layout"
import Seo from "../components/seo"
import '../assets/scss/app.scss';
import Home from "./Home";

const IndexPage = () => (
  <Layout >
    <Home />
  </Layout>

)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />
export default IndexPage
