import { Link } from 'gatsby'
import React from 'react'

const Button = ({ label, as = 'button', className, href = '/' }) => {
    return (
        as == 'button' ? <button className={`btn btn-3b ${className}`}>
            <span className='text'>{label}</span>
            <span className='semi-circle'></span>
        </button> : <Link className={`btn btn-3b ${className}`} to={href}>
            <span className='text'>{label}</span>
            <span className='semi-circle'></span>
        </Link>
    )
}

export default Button