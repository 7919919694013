import React from 'react'
import * as Styles from "./description.module.scss"
import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';

const Description = ({ title, description }) => {
    return (
        <ScrollAnimation animateIn="animate__animated animate__fadeInUp" animateOut='animate__animated animate__fadeInUp' animateOnce={true}>
            <div className={`${Styles.heroDesContainer}`}>
                <div className={`${Styles.heroDesContent} container`}>
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
            </div>
        </ScrollAnimation>
    )
}

export default Description