import React from "react"
import * as Styles from "./AboutUs.module.scss"
import teamWork from "../../assets/img/teamWork.jpg"
import "animate.css"
import ScrollAnimation from "react-animate-on-scroll"
import Layout from "../../layout"
import Seo from "../../components/seo"
import SlidesTechnology from "../../components/slider/index.jsx"
import CompanyValues from "../../components/companyValues"
import ui from "../../assets/img/aboutUs.jpg"

const AboutUs = () => {
  return (
    <Layout>
      <div className="aboutUsContainer">
        <div className={`${Styles.aboutBanner}`}>
          <img src={ui} alt="logo" />
          <div className={Styles.heading}>
            <h1>
              Bringing your ideas to <span>Reality</span>
            </h1>
          </div>
        </div>

        <ScrollAnimation
          animateIn="animate__animated animate__fadeInUp"
          animateOut="animate__animated animate__fadeInUp"
          animateOnce={true}
        >
          <div className={Styles.aboutContent}>
            <h1>
              Who We <span>Are</span>
            </h1>
            <p>
              3B is a Software Product Development and IT Consulting firm
              located in Pakistan and is committed to deliver cost-effective IT
              solutions without compromising on quality. We offer Custom
              Software Development and IT Staff Augmentation services built on
              top of web, mobile, wearables, IoT, analytics, and engineering
              methodologies to help start-ups, small-medium businesses, and
              enterprises to grow in market. We work as a strategic development
              partner with our clients and help them throughout the Software
              Product Development Lifecycle
            </p>
          </div>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="animate__animated animate__fadeInUp"
          animateOut="animate__animated animate__fadeInUp"
          animateOnce={true}
        >
          <div className={Styles.aboutDetailContainer}>
            <img src={teamWork} alt="teamWork" />
            <div className={Styles.aboutDetail}>
              <h1>
                What Makes Us <span>Different</span>
              </h1>
              <p>
                We use value-based work model which focuses on client
                satisfaction, fast service delivery, and open communication. Our
                experienced team helps us to provide excellent product
                development solutions which helps us to maintain 90% client
                retention.
              </p>
              <p>
                The success of software product ideas depends on creating
                cutting-edge digital experiences with the highest quality
                solutions. Our strategic development partnership and agile
                methodologies add value to your product idea by delivering
                quality solutions on time and prioritizing the client and its
                end users.
              </p>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="animate__animated animate__fadeInUp"
          animateOut="animate__animated animate__fadeInUp"
          animateOnce={true}
        >
          <div className={Styles.aboutTech}>
            <h1>
              We <span>Work</span> With
            </h1>
            <p>Web, Mobile and Cloud Technologies</p>
            <SlidesTechnology />
          </div>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="animate__animated animate__fadeInUp"
          animateOut="animate__animated animate__fadeInUp"
          animateOnce={true}
        >
          <CompanyValues />
        </ScrollAnimation>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="About Us" />
export default AboutUs
