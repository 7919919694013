import React from 'react'
import * as Styles from "./serviceDetail.module.scss";
import serviceImg from "../../assets/img/serviceImg.png"

const ServiceDetail = ({ img, heading, detail }) => {
    return (
        <div className={`${Styles.serviceDetailContainer} container`}>

            <div className={Styles.serviceContent}>
                <h1>{heading}</h1>
                <p>{detail}</p>
            </div>
            <div className={Styles.serviceDetailImg}>
                <img src={img} alt="logo" />
            </div>
        </div>
    )
}

export default ServiceDetail