exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-home-hero-jsx": () => import("./../../../src/pages/Home/hero.jsx" /* webpackChunkName: "component---src-pages-home-hero-jsx" */),
  "component---src-pages-home-index-jsx": () => import("./../../../src/pages/Home/index.jsx" /* webpackChunkName: "component---src-pages-home-index-jsx" */),
  "component---src-pages-home-our-process-jsx": () => import("./../../../src/pages/Home/our-process.jsx" /* webpackChunkName: "component---src-pages-home-our-process-jsx" */),
  "component---src-pages-home-skills-jsx": () => import("./../../../src/pages/Home/skills.jsx" /* webpackChunkName: "component---src-pages-home-skills-jsx" */),
  "component---src-pages-home-what-we-do-jsx": () => import("./../../../src/pages/Home/what-we-do.jsx" /* webpackChunkName: "component---src-pages-home-what-we-do-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-using-ssr-jsx": () => import("./../../../src/pages/using-ssr.jsx" /* webpackChunkName: "component---src-pages-using-ssr-jsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-service-template-js": () => import("./../../../src/templates/service-template.js" /* webpackChunkName: "component---src-templates-service-template-js" */),
  "component---src-templates-technology-template-js": () => import("./../../../src/templates/technology-template.js" /* webpackChunkName: "component---src-templates-technology-template-js" */)
}

