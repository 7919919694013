import React from 'react'
import * as Styles from "./values.module.scss"

const Values = ({ img, detail, heading }) => {
    return (
        <div className={Styles.valuesContainer}>
            <div className={`${Styles.valuesContent}`}>
                <img src={img} alt="values" />
                <h4>{heading}</h4>
            </div>
            <div className={Styles.valuesDetail}>
                <p>{detail}</p>
            </div>
        </div>
    )
}

export default Values