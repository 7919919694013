import React from 'react'
import * as Styles from "./banner.module.scss";
import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';
import back from "../../assets/img/techBack.jpg"

const Banner = ({ img, heading, detail }) => {
    return (
        <div className={`${Styles.bannerContainer} container-fluid`}>
            <img src={back} alt="logoBack" />

            <div className={`${Styles.bannerSection} container`}>
                <ScrollAnimation className={`${Styles.heroCoverContainer}`} animateIn="animate__animated animate__fadeInUp" animateOut='animate__animated animate__fadeInUp' animateOnce={true}>
                    <div className={Styles.coverContent}>
                        <h1>{heading}</h1>
                        <p>{detail}</p>
                    </div>
                    <div className={Styles.coverImg}>
                        <img src={img} alt="coverImg" />
                    </div>
                </ScrollAnimation>
            </div>
        </div>

    )
}

export default Banner