import React from "react"
import * as Styles from "./privacy.module.scss"
import Layout from "../../layout/index"
import secure from "../../assets/img/secure.png"
import RingUs from "../../components/RingUs"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className={`${Styles.privacyConatiner} container`}>
        <h1>Privacy Policy for 3B International</h1>
        <p>
          At 3B one of our main priorities is the privacy of our visitors. This
          Privacy Policy document contains types of information that is
          collected and recorded by us and how we use it. If you have additional
          questions or require more information about our Privacy Policy, do not
          hesitate to contact us.
        </p>
        <div className={Styles.contentContainer}>
          <img src={secure} alt="secure" />

          <div className={Styles.content}>
            <h1>Communication of Information</h1>
            <p>
              We at 3B International shall ensure to preserve user privacy,
              however there are certain circumstances where 3B may need to
              disclose personal information when required by law wherein we have
              a good-faith belief that such action is necessary to comply with
              the current judicial proceeding, a court order or legal process
              served on our products, services, or website.
            </p>
            <p>
              3B International keeping in view the privacy of personal
              information received from minors/children (individuals under the
              age of 18), shall not knowingly receive/collect personal
              information at company portals and websites. If 3B comes to know
              that there is personal information received from a minor is
              available at company portals and websites, it shall be deleted.
            </p>
            <p>
              In-case a customer or client’s personally identifiable information
              changes (such as zip code, phone, email, or postal address), or if
              a client no longer continues to use service, 3B International
              shall correct, update or delete users’ personally identifiable
              information.
            </p>
          </div>
        </div>
        <RingUs />
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
