import React from 'react'
import * as Styles from "./detail.module.scss"
import reactLogo from "../../assets/img/reactImg.png"
import reactMobile from "../../assets/img/reactMobile.png"
import and from "../../assets/img/androidComp.png"
import ang from "../../assets/img/angularComp.png"
import doc from "../../assets/img/dockerComp.png"
import nod from "../../assets/img/nodeComp.png"
import pos from "../../assets/img/postComp.png"

import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';

const Detail = ({ img, title, description }) => {
    return (
        <ScrollAnimation animateIn="animate__animated animate__fadeInUp" animateOut='animate__animated animate__fadeInUp' animateOnce={true}>
            <div className={`${Styles.heroDetailContainer} container`}>
                <div className={Styles.detailImg}>
                    <img src={img} alt="detailImg" />
                </div>
                <div className={Styles.detailContent}>
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
            </div>
        </ScrollAnimation>

    )
}

export default Detail