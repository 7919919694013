import React from 'react'
import * as Styles from "./ring.module.scss";

const RingUs = () => {
    return (
        <div className={Styles.ringContainer}>
            <h1>Give us a call</h1>
            <a href="tel:+924235881063" className='link-primary' type='button'> Ring here <span></span></a>
        </div>
    )
}

export default RingUs