import React from 'react'
import * as Style from "./companyValues.module.scss"
import Values from '../values'
import communicate from "../../assets/img/communicate.svg"
import health from "../../assets/img/health-care.svg"
import humble from "../../assets/img/humble.svg"
import trust from "../../assets/img/reinforce-trust.svg"
import top from "../../assets/img/top-three.svg"

const CompanyValues = () => {
    return (
        <div className={Style.companyValueContainer}>
            <h1>Values</h1>
            <Values img={communicate} heading={"Communicate"}
                detail={"Open and continuous discussion among the company, its employees, and the clients ensure that all matters and decisions of consequences are fully discussed and agreed by all parties."} />

            <Values img={health} heading={"Reinforce Trust"}
                detail={"Encourage yourself and those around you to act productively. Appreciate efforts and motivate to build a stronger and better team."} />

            <Values img={humble} heading={"Humble"}
                detail={"No self-aggrandizing image of yourself. Always contribute no matter how small your effort is. Always learn from others."} />

            <Values img={trust} heading={"Care"}
                detail={"Build trust with care, genuine concern, and thoughtfulness towards the clients and colleagues."} />

            <Values img={top} heading={"Nuture Deep Expertise"}
                detail={"Offer a rich blend of business insights & technology depth to pave transformation."} />

        </div>
    )
}

export default CompanyValues