import React from 'react'
import * as Styles from "./contactCard.module.scss"
import pak from "../../assets/img/pakistan.png"

const ContactCard = () => {
    return (
        <div className={Styles.contactCardContainer}>
            <h2>Get in touch</h2>
            <p>sales@3binternational.pk</p>
            <div className={Styles.cardImg}>
                <img src={pak} alt="pakistan" />
                <p>+924235881063</p>
            </div>
        </div>
    )
}

export default ContactCard