import * as React from "react"
import * as Styles from "./tech.module.scss";
import Layout from "../layout"
import Seo from "../components/seo"

import Banner from "../components/bannerTech/banner"
import Description from "../components/descriptionTech/description"
import Detail from "../components/detailTech/detail"

const UsingDSG = ({ pageContext }) => {

  const { data } = pageContext;
  return <Layout>
    <div className='heroContainer'>
      <Banner img={data.avatar?.url} heading={data.title} detail={data.titleLine} />
      <Description title={data.descTitle} description={data.description.description} />
      <Detail img={data.detailImg.url} title={data.detailTitle} description={data.detail.detail} />
    </div>
  </Layout>

}
export const Head = () => <Seo title="Using DSG" />

export default UsingDSG
