import React, { useState, useEffect } from 'react'
import * as Styles from "./contactForm.module.scss"
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import { collection, getDocs, addDoc } from "firebase/firestore"
import { db } from "../../firebase/firebase-config"
import * as yup from 'yup';

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    name: yup
        .string('Enter your name')
        .required('Name is required'),
    // phone: yup
    //     .string('Enter your phone')
    //     .required('Phone is required'),
    detail: yup
        .string('Enter your detail')
        .required('Detail is required'),
    organization: yup
        .string('Enter your organization')
        .required('Organization is required'),
});

const ContactForm = () => {

    const [users, setUsers] = useState([])
    const userCollectionRef = collection(db, "userDetail")

    useEffect(() => {
        const getUsers = async () => {
            const data = await getDocs(userCollectionRef)
            setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }
        getUsers()
    }, [])

    const createUser = async (values) => {
        await addDoc(userCollectionRef,
            { name: values.name, email: values.email, detail: values.detail, phone: values.phone, organization: values.organization })
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            name: '',
            phone: '',
            detail: '',
            organization: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            console.log("Form Values", values.name)
            console.log("Firebase Values", users)
            resetForm();
            createUser(values)
        },
    });


    return (
        <div className={Styles.formContainer}>

            <div className={Styles.contactForm}>
                <h1>Let's get started</h1>
                <p className='mb-4'>Have any queries or need any help? Please fill out the form below and we will get back to you shortly.</p>

                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='row'>
                            <div className='col-md-6 col-12'>
                                <TextField id="name" name='name' helperText={formik.touched.name && formik.errors.name} error={formik.touched.name && Boolean(formik.errors.name)} value={formik.values.name} onChange={formik.handleChange} label="Your Name" variant="outlined" fullWidth />
                            </div>
                            <div className='col-md-6 col-12 mt-md-0 mt-4'>
                                <TextField id="email" name='email' helperText={formik.touched.email && formik.errors.email} error={formik.touched.email && Boolean(formik.errors.email)} value={formik.values.email} onChange={formik.handleChange} label="Your Email" variant="outlined" fullWidth />
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className='col-md-6 col-12'>
                                <TextField id="phone" name='phone' value={formik.values.phone} onChange={formik.handleChange} label="Your Phone (Optional)" variant="outlined" fullWidth />
                            </div>
                            <div className='col-md-6 col-12 mt-md-0 mt-4'>
                                <TextField id="organization" name='organization' helperText={formik.touched.organization && formik.errors.organization} error={formik.touched.organization && Boolean(formik.errors.organization)} value={formik.values.organization} onChange={formik.handleChange} label="Your Organization" variant="outlined" fullWidth />
                            </div>
                        </div>

                        <div className='mt-4'>
                            <TextField
                                id="detail"
                                name='detail'
                                value={formik.values.detail}
                                onChange={formik.handleChange}
                                label="Let us know a bit more about project you have in mind"
                                multiline
                                rows={4}
                                fullWidth
                                helperText={formik.touched.detail && formik.errors.detail} error={formik.touched.detail && Boolean(formik.errors.detail)}
                            />
                        </div>

                        <div className="d-flex justify-content-end mt-3">
                            <button className="btn btn-primary btn-3b" type="submit">Submit</button>
                        </div>

                    </form>

                </div>


            </div>

        </div>
    )
}

export default ContactForm