import React from 'react'
import * as Styles from "./serviceQA.module.scss";
import _Accordion from "../Accordion"

const ServiceQA = ({ title, detail }) => {
    return (
        <div className={`${Styles.serviceQAContainer} container`}>
            {/* <h1>{title}</h1>
            <_Accordion title={detail[0]} description={detail[1]} />
            <_Accordion title={detail[2]} description={detail[3]} />
            <_Accordion title={detail[4]} description={detail[5]} />
            <_Accordion title={detail[6]} description={detail[7]} /> */}
        </div>
    )
}

export default ServiceQA