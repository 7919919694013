// extracted by mini-css-extract-plugin
export var addingValueContainer = "home-module--addingValueContainer--ZS5vc";
export var anySkillContainer = "home-module--anySkillContainer--4RSJF";
export var arrow = "home-module--arrow--QbLTL";
export var btnStyle = "home-module--btnStyle--ohYsc";
export var buttonText = "home-module--button-text--iFWYc";
export var cardContainer = "home-module--cardContainer--GHDyA";
export var cardRow = "home-module--cardRow--5srBl";
export var circle = "home-module--circle--3FeAr";
export var contactLink = "home-module--contactLink--Du+NI";
export var contactformContainer = "home-module--contactformContainer--yoWvm";
export var custom = "home-module--custom--UGI7q";
export var description = "home-module--description--8wHho";
export var dot = "home-module--dot--T+jrZ";
export var five = "home-module--five---WTxa";
export var four = "home-module--four--7MCi2";
export var heading = "home-module--heading--ErZD+";
export var heroContainer = "home-module--heroContainer--c0nWJ";
export var heroLeftSection = "home-module--heroLeftSection--7HTU5";
export var heroRightSection = "home-module--heroRightSection--8S92e";
export var heroWrapper = "home-module--heroWrapper--Z+C1R";
export var icon = "home-module--icon--Q0XqH";
export var iconArrow = "home-module--iconArrow--zJsQO";
export var imageWrapper = "home-module--imageWrapper--eew07";
export var infoWrapper = "home-module--infoWrapper--1ri0S";
export var learnMore = "home-module--learnMore--DYqbk";
export var one = "home-module--one--LcGZS";
export var ourProcessContainer = "home-module--ourProcessContainer--rd-p3";
export var ourProcessSection = "home-module--ourProcessSection--KcMZ6";
export var processCard = "home-module--processCard--hzFBQ";
export var progressCircle = "home-module--progressCircle--ZoH82";
export var scaleEffectivelyContainer = "home-module--scaleEffectivelyContainer--MigU8";
export var sectionDetails = "home-module--sectionDetails--p9YYK";
export var sectionHeading = "home-module--sectionHeading--VDVYT";
export var serviceButtons = "home-module--serviceButtons--Rw7IU";
export var six = "home-module--six--Wc-vK";
export var skillLogoColor = "home-module--skillLogoColor--L886w";
export var skillLogoListContainer = "home-module--skillLogoListContainer--ffNQQ";
export var skillSectionContainer = "home-module--skillSectionContainer--gKl-t";
export var skillsLogoWrapper = "home-module--skillsLogoWrapper--NQFfP";
export var slideButton = "home-module--slideButton--iLcSD";
export var slidesTech = "home-module--slidesTech--eemBp";
export var text = "home-module--text--0MzsN";
export var three = "home-module--three--4jT4W";
export var two = "home-module--two--s-afB";
export var whatWeDoContainer = "home-module--whatWeDoContainer--aFu1g";
export var whatWeDoSection = "home-module--whatWeDoSection--chqeF";