import React from 'react'
import * as Styles from './home.module.scss';
import Accordion from '../../components/Accordion'

const OurProcess = () => {
    return (
        <section className={Styles.ourProcessSection}>
            <div className={`container ${Styles.ourProcessContainer}`}>
                <div className={Styles.sectionHeading}>
                    <h3>Our Process</h3>
                    <p>Powered by creativity, innovation and excellence</p>
                </div>
                <div className={`row align-row ${Styles.sectionDetails}`}>
                    <div className='col-6'>
                        <div className={Styles.progressCircle}>
                            <div className={`${Styles.one} ${Styles.dot}`}></div>
                            <div className={`${Styles.two} ${Styles.dot}`}></div>
                            <div className={`${Styles.three} ${Styles.dot}`}></div>
                            <div className={`${Styles.four} ${Styles.dot}`}></div>
                            <div className={`${Styles.five} ${Styles.dot}`}></div>
                            <div className={`${Styles.six} ${Styles.dot}`}></div>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className={Styles.processCard}>
                            <Accordion title="Requirements" details="" />
                            <Accordion title="Analysis" details="" />
                            <Accordion title="Design" details="" />
                            <Accordion title="Development" details="" />
                            <Accordion title="Testing" details="" />
                            <Accordion title="Launch" details="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurProcess