import React from 'react'
import ContactCard from '../../components/contactCard'
import ContactForm from '../../components/contactForm'
import ContactInst from '../../components/contactInst'
import * as Styles from "./contactUs.module.scss"
import contactImg from "../../assets/img/contact.png"
import 'animate.css';
import ScrollAnimation from 'react-animate-on-scroll';
import Layout from '../../layout'
import RingUs from '../../components/RingUs'
import contactBanner from "../../assets/img/contactUs.jpg";

const ContactUs = () => {
    return (
        <Layout>
            <div className='contactUsContainer'>
                <div className={`${Styles.contactBanner}`}>
                    <img src={contactBanner} alt="logo" />
                    <div className={Styles.heading}>
                        <h1>Contact Us</h1>
                        <p>Reach us to give your ideas the technology it needs</p>
                    </div>
                </div>

                <ScrollAnimation animateIn="animate__animated animate__fadeInUp" animateOut='animate__animated animate__fadeInUp' animateOnce={true}>
                    <div className={Styles.formContentContainer}>
                        <ContactForm />
                        <ContactCard />
                    </div>
                </ScrollAnimation>

                <ScrollAnimation animateIn="animate__animated animate__fadeInUp" animateOut='animate__animated animate__fadeInUp' animateOnce={true}>
                    <div className={Styles.proceedInst}>
                        <h1 className='text-center'>How to proceed</h1>
                        <div className={Styles.proceedImg}>
                            <img src={contactImg} alt="contactImg" />
                        </div>
                        <div className={Styles.proceedCardContainer}>
                            <ContactInst />
                        </div>
                    </div>
                </ScrollAnimation>

                <ScrollAnimation animateIn="animate__animated animate__fadeInUp" animateOut='animate__animated animate__fadeInUp' animateOnce={true}>
                    <RingUs />
                </ScrollAnimation>

            </div>
        </Layout>
    )
}

export default ContactUs