import React from 'react'
import * as Styles from "./contactInst.module.scss"
import { motion } from 'framer-motion'

const ContactInst = () => {
    return (
        <>
            <motion.div
                className={Styles.contactInstContainer}
                whileHover={{
                    scale: 1.05,
                    boxShadow: "15px 15px 30px #bebebe, -15px -15px 30px #ffffff",
                    textShadow: "15px 15px 30px #bebebe, -15px -15px 30px #ffffff",
                    transition: { type: 'spring', delay: 0.2 }
                }}
            >
                <h3>Contact</h3>
                <p>Share your business requirements and ideas with us. We have a transparent & quick process for corporate privacy and sign NDA with us instantly.</p>
            </motion.div>

            <motion.div
                className={Styles.contactInstContainer}
                whileHover={{
                    scale: 1.05,
                    boxShadow: "15px 15px 30px #bebebe, -15px -15px 30px #ffffff",
                    textShadow: "15px 15px 30px #bebebe, -15px -15px 30px #ffffff",
                    transition: { type: 'spring', delay: 0.2 }
                }}>
                <h3>Analysis</h3>
                <p>Our representative will closely work with you and provide you an in-depth discussion for your business so that we can decide the optimal business solution for you.</p>
            </motion.div>

            <motion.div
                className={Styles.contactInstContainer}
                whileHover={{
                    scale: 1.05,
                    boxShadow: "15px 15px 30px #bebebe, -15px -15px 30px #ffffff",
                    textShadow: "15px 15px 30px #bebebe, -15px -15px 30px #ffffff",
                    transition: { type: 'spring', delay: 0.2 }
                }}>
                <h3>Proposal</h3>
                <p>You will get our technical and commercial proposal after completion of the initial analysis. Also, a proposal walkthrough for modification of the proposal on your terms</p>
            </motion.div>
        </>

    )
}

export default ContactInst