import React from 'react';
import * as Styles from "./serviceProcess.module.scss";

const ServiceProcess = () => {
    return (
        <div className={`${Styles.exeContainer}`}>
            <h1 className='text-center'>Execution Process</h1>
            <div className={`${Styles.processContainer} container`}>
                <div className={Styles.processContent}>
                    <h3>Discovery</h3>
                    <li>Understanding your business</li>
                    <li>Interviews & observations</li>
                    <li>Functional requirements</li>
                </div>
                <div className={Styles.processContent}>
                    <h3>Build and Iterate</h3>
                    <li>Interaction design</li>
                    <li>High-fidelity mockups</li>
                    <li>UI Style guide/brand identity</li>
                </div>
                <div className={Styles.processContent}>
                    <h3>Wireframes</h3>
                    <li>Concept sketching</li>
                    <li>System/Process flow</li>
                    <li>Information architecture</li>
                </div>
            </div>
        </div>
    )
}

export default ServiceProcess